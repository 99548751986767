<template>
  <div class="h-full relative">
    <module-form-header :label="$t('patient.lab.lab')" :patient="info" :data="{}" @cancel="cancel" class="shadow-md" />

    <validation-observer :ref="'formobserver'" v-slot="{ handleSubmit, invalid }">
      <form
        @submit.prevent="handleSubmit(submitLab)"
        class="flex flex-col max-h-screen -mt-10 pt-10"
        v-if="definitions"
      >
        <div class="overflow-y-auto px-4 shadow-md pb-10 pt-4">
          <validation-provider v-slot="validation" rules="required" :name="$t('date')">
            <label for="observation_date" class="block text-sm"
              >{{ $t("date") }}
              <component
                name="observation_date"
                v-for="(element, key) in layout.elements"
                :key="key"
                :ref="element.name"
                :is="elementType(element)"
                v-model="form[element.name]"
              />
            </label>
            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
              {{ validation.errors[0] }}
            </p>
          </validation-provider>

          <div v-for="def in definitions" :key="def.name">
            <lab-value
              :key="def.name"
              @input="updateValue"
              :name="def.name"
              :definition="getDefinition(def.name)"
              :value="getValue(def.name)"
              :unit="def.unit"
              :operator="def.operator"
            />
          </div>
        </div>

        <transition name="fade">
          <div class="bg-gray-800 text-gray-50" v-if="errors">
            <div class="h-1 bg-red-500"></div>
            <div class="p-2 flex justify-between">
              <details>
                <summary>{{ $t("form.error.summarytext") }}</summary>
                <div v-for="(error, key) in errors" :key="key" class="px-1 hover:bg-gray-500 rounded">
                  <span class="mr-1">{{ getTranslation(error.name) }}:</span>
                  <span v-if="error.type === 'option'"
                    >{{
                      $t("validation.error.option", {
                        value: error.value,
                        acceptable: error.acceptable.map(d => d.translations.sv).join(", "),
                      })
                    }}
                  </span>
                  <span v-if="error.type === 'minimum'">{{
                    $t("validation.error.minimum", { value: error.value, acceptable: error.acceptable })
                  }}</span>
                  <span v-if="error.type === 'maximum'">
                    {{ $t("validation.error.maximum", { value: error.value, acceptable: error.acceptable }) }}
                  </span>
                </div>
              </details>
              <div>
                <cm-button size="small" @click="errors = null">{{ $t("button.close") }}</cm-button>
              </div>
            </div>
          </div>
        </transition>

        <module-form-menu :invalid="invalid" @cancel="cancel" :formid="form.id" @delete-post="deleteLab" />
      </form>

      <module-form-footer :data="{}" />
    </validation-observer>
  </div>
</template>

<script>
import { intersection } from "lodash";
import { mapState } from "vuex";
import { getElementType } from "@/utils/form.js";
import { ValidationObserver, ValidationProvider, localize, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

import ModuleFormMenu from "@/components/patient/module/ModuleFormMenu";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";
import ModuleFormFooter from "@/components/patient/module/ModuleFormFooter";
import LabValue from "@/components/patient/lab/LabValue";

function loadVeeValidateLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

export default {
  name: "LabForm",
  props: ["entry"],
  components: {
    ValidationObserver,
    ValidationProvider,
    ModuleFormMenu,
    ModuleFormHeader,
    ModuleFormFooter,
    LabValue,
  },
  data() {
    return {
      errors: null,
      form: {
        id: null,
        observation_date: null,
        data: [],
      },
      layout: {
        elements: [
          {
            title: "date",
            label: "date",
            element: "date",
            name: "observation_date",
            required: true,
            rule: null,
            translations: {
              sv: "Datum",
              en: "Date",
            },
            type: "default",
          },
        ],
      },
    };
  },
  beforeMount() {
    loadVeeValidateLocale(this.userLocale ?? "sv");
    this.load();
  },
  watch: {
    entry: {
      immediate: true,
      handler: function (v) {
        if (v !== null) {
          const newVal = JSON.parse(JSON.stringify(v));
          // //this is only needed on imported lab values that has not been converted to a new name
          // newVal.data = newVal.data.map(d => {
          //   const tmp = this.getDefinitionByAlias(d.name);

          //   d.name = tmp ? tmp.name : d.name;
          //   return d;
          // });
          this.form = newVal;
        } else {
          this.form = {
            id: null,
            observation_date: null,
            data: [],
          };
        }
      },
    },
  },
  computed: {
    ...mapState("patient", {
      info: state => state.info,
    }),
    ...mapState("moduledata", {
      labdata: state => state.lab,
    }),
    ...mapState("lab", {
      definitions: state => state.definitions,
    }),
    occupiedNames() {
      return this.form.data.map(d => d.name);
    },
    availableDefinitions() {
      return this.definitions
        .filter(d => !this.occupiedNames.includes(d.name) && intersection(d.aliases, this.occupiedNames).length === 0)
        .map(d => d.name);
    },
  },
  methods: {
    load() {
      this.$store.dispatch("lab/loadDefinitions");
    },
    cancel() {
      this.$modal.hide("lab-form");
      this.$emit("cancel");
    },
    elementType(d) {
      return getElementType(d);
    },
    getValue(name) {
      const entryValue = this.form.data.find(d => d.name === name);

      return entryValue?.value;
    },
    getUnit(labname) {
      const def = this.getDefinition(labname) ?? this.getDefinitionByAlias(labname);

      return def && def.units.length > 0 ? def.units[0] : null;
    },
    updateValue(name, value) {
      let entryValue = this.form.data.find(d => d.name === name);

      if (entryValue) {
        entryValue.value = value;
      } else {
        this.form.data.push({ name, value });
      }
    },
    updateUnit(name, value) {
      let entryValue = this.form.data.find(d => d.name === name);

      if (entryValue) {
        entryValue.unit = value;
      } else {
        this.form.data[name]["unit"] = value;
      }
    },
    submitLab() {
      this.errors = null;
      this.pendingSave = true;

      this.$store
        .dispatch("patient/submitLab", {
          id: this.id,
          formname: this.name,
          data: this.form,
        })
        .then(() => {
          this.pendingSave = false;
          this.$modal.hide("lab-form");
        })
        .catch(e => {
          this.errors = e.response?.data?.errors ?? [];
        });
    },
    getDefinition(labname) {
      const directHit = this.definitions.filter(d => d.name === labname);
      if (directHit.length === 1) {
        return directHit[0];
      }

      const alias = this.getDefinitionByAlias(labname);
      if (alias) {
        return alias;
      }

      return null;
    },
    getDefinitionByAlias(labname) {
      const alias = this.definitions.filter(d => d.aliases.filter(a => a === labname).length > 0);

      if (alias.length === 1) {
        return alias[0];
      }
      return null;
    },
    getTranslation(labname) {
      if (!this.definitions) return;

      const directHit = this.definitions.filter(d => d.name === labname);
      if (directHit.length === 1) {
        return directHit[0].translations["sv"] ?? directHit[0].translations["en"] ?? directHit[0].name;
      }

      const alias = this.getDefinitionByAlias(labname); //this.definitions.filter(d => d.aliases.filter(a => a === labname).length > 0);
      if (alias) {
        return alias.translations["sv"] ?? alias.translations["en"] ?? alias.name;
      }

      return "--- " + labname;
    },
    deleteLab() {
      this.$store.dispatch("patient/deleteLabRegistration", this.form.id).then(() => {
        this.form = null;
        this.$modal.hide("lab-form");
      });
    },
  },
};
</script>

<style>
</style>