<template>
  <div v-if="loaded" class="h-full" ref="gridcontainer">
    <div class="flex flex-col h-full text-xs">
      <div class="bg-red-200">
        <div class="py-1 px-2 bg-gray-700 text-white flex justify-between">
          <div>
            <cm-button @click="showLabForm(null)" size="small" visual="success">{{ $t("button.create") }}</cm-button>
            <span class="mx-2">{{ $t("patient.lab.lab") }}</span>
          </div>

          <div>{{ $tc("module.tab.entries", labdata ? labdata.length : 0) }}</div>
        </div>
      </div>

      <table class="sticky-header w-full text-xs">
        <thead ref="tableheader">
          <tr></tr>
          <tr>
            <th @click="setSort('lab_date')">
              <div class="flex items-center">
                <a href="#" slot="reference" class="top">
                  <span class="px-1 mr-1 whitespace-nowrap">{{ $t("patient.lab.date") }}</span>
                </a>

                <span v-show="'lab_date' == sortcolumn">
                  <svg
                    v-if="sortasc"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    class="w-4 h-4"
                  >
                    <path d="M5 10l7-7m0 0l7 7m-7-7v18" />
                  </svg>
                  <svg
                    v-if="!sortasc"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    class="w-4 h-4"
                  >
                    <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </span>
              </div>
            </th>
            <th @click="setSort('lab_type')">
              <div class="flex items-center">
                <a href="#" slot="reference" class="top">
                  <span class="px-1 mr-1 whitespace-nowrap">{{ $t("patient.lab.variable") }}</span>
                </a>

                <span v-show="'lab_type' == sortcolumn">
                  <svg
                    v-if="sortasc"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    class="w-4 h-4"
                  >
                    <path d="M5 10l7-7m0 0l7 7m-7-7v18" />
                  </svg>
                  <svg
                    v-if="!sortasc"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    class="w-4 h-4"
                  >
                    <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </span>
              </div>
            </th>
            <th @click="setSort('lab_value')">
              <div class="flex items-center">
                <a href="#" slot="reference" class="top">
                  <span class="px-1 mr-1 whitespace-nowrap">{{ $t("patient.lab.value") }}</span>
                </a>

                <span v-show="'lab_value' == sortcolumn">
                  <svg
                    v-if="sortasc"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    class="w-4 h-4"
                  >
                    <path d="M5 10l7-7m0 0l7 7m-7-7v18" />
                  </svg>
                  <svg
                    v-if="!sortasc"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    class="w-4 h-4"
                  >
                    <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="labdata" :style="dynamicGridHeight">
          <tr
            v-for="(row, key) in labdata"
            :key="key"
            class="group odd:bg-gray-100 first:border-t border-b border-gray-200"
            @click="showLabForm(row)"
          >
            <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
              {{ formatDate(row.observation_date) }}
            </td>
            <td
              class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer flex justify-between"
              v-for="(lab, key) in row.data"
              :key="key"
            >
              <div>{{ getTranslation(lab.name) }}</div>
              <div>{{ getLabValue(lab.name, lab.value) }}</div>
            </td>
            <td class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer"></td>
          </tr>
        </tbody>
      </table>

      <modal
        name="lab-form"
        :click-to-close="true"
        :scrollable="true"
        :resizable="true"
        :draggable="false"
        :adaptive="false"
        :reset="true"
        :pivotY="0.1"
        :height="'auto'"
        classes="bg-white rounded shadow-md transition-all object-contain overflow-visible text-sm"
      >
        <lab-form :entry="selectedRow" @cancel="selectedRow = null" />
      </modal>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/date";
import { mapState } from "vuex";
import LabForm from "@/components/patient/lab/LabForm";

export default {
  name: "LabGrid",
  props: ["height"],
  components: {
    LabForm,
  },
  data() {
    return {
      loaded: false,
      labData: null,
      sortcolumn: null,
      sortasc: false,
      tabData: null,
      gridHeight: null,
      variableMeta: [],
      selectedRow: null,
    };
  },
  created() {
    this.load();
  },
  computed: {
    ...mapState("patient", {
      // structure: state => state.labStructure,
    }),
    ...mapState("lab", {
      definitions: state => state.definitions,
    }),
    ...mapState("moduledata", {
      labdata: state => state.lab,
    }),
    dynamicGridHeight() {
      return this.gridHeight;
    },
  },
  methods: {
    async load() {
      await this.$store.dispatch("moduledata/loadLabData");
      await this.$store.dispatch("lab/loadDefinitions");
      // await this.$store.dispatch("patient/labStructure");

      // this.structure.forEach(module => {
      //   module.variables.forEach(variable => {
      //     if (variable.options) {
      //       var options = [];
      //       variable.options.forEach(option => {
      //         options[option.value] = option.name;
      //       });
      //     }

      //     this.variableMeta[variable.varname] = {
      //       name: variable.name,
      //       unit: variable.unit,
      //       options: options,
      //     };
      //   });
      // });

      this.loaded = true;
    },
    showLabForm(row) {
      this.selectedRow = row;
      this.$modal.show("lab-form");
    },
    filteredValues(date) {
      return this.data.filter(d => date == d.lab_date);
    },
    getDefinition(name) {
      const directHit = this.definitions.filter(d => d.name === name);
      if (directHit.length === 1) {
        return directHit[0];
      }

      const alias = this.getDefinitionByAlias(name);
      if (alias) {
        return alias;
      }

      return null;
    },
    getDefinitionByAlias(name) {
      const alias = this.definitions.filter(d => d.aliases.filter(a => a === name).length > 0);

      if (alias.length === 1) {
        return alias[0];
      }
      return null;
    },

    getTranslation(name) {
      const def = this.getDefinition(name);

      return def ? def.translations["sv"] ?? def.translations["en"] : "[untranslated] " + name;
    },
    getLabValue(name, value) {
      const def = this.getDefinition(name);

      if (Object.keys(def.options).length > 0) {
        const entry = Array.from(Object.values(def.options)).find(d => +d.value === +value);
        return entry?.translations.sv ?? entry?.translations.en;
      }

      return value;
    },
    setSort(varname) {
      this.sortasc = varname === this.sortcolumn ? !this.sortasc : this.sortasc;
      this.sortcolumn = varname;
      var sort;
      this.tabData = JSON.parse(JSON.stringify(this.data)).sort((a, b) => {
        switch (varname) {
          case "lab_value":
            sort = a[varname] - b[varname]; //Not sure how to handle lab variables with optionslist or textinput but most of them is numeric so think this is better then localecompare
            break;
          case "lab_type":
            sort = this.variableMeta[a[varname]].name.localeCompare(this.variableMeta[b[varname]].name);
            break;
          default:
            sort = a[varname].localeCompare(b[varname]);
            break;
        }

        if (!this.sortasc) {
          if (sort > 0) {
            return -1;
          } else if (sort < 0) {
            return 1;
          }
        }

        return sort;
      });
    },
    dynamicHeight() {
      this.$nextTick(() => {
        const th = this.$refs.tableheader ? this.$refs.tableheader.clientHeight : 50;

        this.gridHeight = { height: this.height - th + "px" };
      });
    },
    formatDate(d) {
      return dateFormat(d);
    },
  },
  watch: {
    data() {
      this.sortasc = false;
      this.setSort("lab_date");
      this.sortcolumn = null;
    },
    height: {
      immediate: false,
      handler: "dynamicHeight",
    },
  },
};
</script>

<style>
.striped tr > td {
  background-color: inherit;
  border-color: inherit;
}
.sticky-header tbody {
  display: block;
  overflow-y: scroll;
}
.sticky-header thead,
.sticky-header tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
